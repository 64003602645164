export * from "ce/api/GitExtendedApi";
import Api from "api/Api";

class GitExtendedApi extends Api {
  static baseURL = `/v1/git`;

  static async updateDefaultBranch(
    baseApplicationId: string,
    branchName: string,
  ) {
    return Api.patch(
      `${GitExtendedApi.baseURL}/branch/app/${baseApplicationId}/default`,
      {},
      undefined,
      { params: { branchName } },
    );
  }

  static async toggleAutoDeployment(baseApplicationId: string) {
    return Api.patch(
      `${GitExtendedApi.baseURL}/auto-deployment/toggle/app/${baseApplicationId}`,
    );
  }

  static async generateCDApiKey(baseApplicationId: string) {
    return Api.post(`/v1/api-key/git/${baseApplicationId}`);
  }
}

export default GitExtendedApi;

import type { AppState } from "ee/reducers";
import type {
  WorkflowRunDetailsData,
  WorkflowRunHistoryData,
} from "ee/reducers/uiReducers/workflowHistoryPaneReducer";

export const getShowRunHistoryPaneState = (state: AppState) =>
  state.ui.workflowHistoryPane.isOpen;

export const getRunHistorySelectedTab = (state: AppState) =>
  state.ui.workflowHistoryPane.context.selectedTab;

export const getRunHistoryResponsePaneHeight = (state: AppState) =>
  state.ui.workflowHistoryPane.context.responseTabHeight;

export const getRunHistoryLoadingState = (state: AppState) =>
  state.ui.workflowHistoryPane.workflowRunHistory.isLoading;

const DEFAULT_RUN_HISTORY_DATA: Array<WorkflowRunHistoryData> = [];

export const getRunHistoryData = (state: AppState, workflowId: string) =>
  state.ui.workflowHistoryPane.workflowRunHistory.data?.[workflowId] ||
  DEFAULT_RUN_HISTORY_DATA;

export const getRunHistoryDetailsLoadingState = (state: AppState) =>
  state.ui.workflowHistoryPane.workflowRunDetails.isLoading;

export const getRunHistoryDetailsData = (
  state: AppState,
  runId: string,
): WorkflowRunDetailsData | undefined =>
  state.ui.workflowHistoryPane.workflowRunDetails.data.hasOwnProperty(runId)
    ? state.ui.workflowHistoryPane.workflowRunDetails.data[runId]
    : undefined;

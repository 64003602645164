export function extractFunctionParams(functionString: string) {
  // Match the parameter part of the function signature
  const match = functionString.match(/\(([^)]*)\)/);

  // Extract the matched parameters and split them by commas
  const parameters = match
    ? match[1].split(",").map((param) => param.trim().split("=")[0].trim())
    : [];

  // Filter out empty strings
  const filteredParameters = parameters.filter((param) => param !== "");

  return filteredParameters;
}

export function cleanArray(arr: unknown[]) {
  // Remove trailing undefined values
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== undefined || arr[i] !== null) {
      return arr.slice(0, i + 1);
    }
  }
  return [];
}

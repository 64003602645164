export * from "ce/workers/Evaluation/getJSActionForEvalContext";
import { getJSActionForEvalContext as ce_getJSActionForEvalContext } from "ce/workers/Evaluation/getJSActionForEvalContext";
import { DEFAULT_JS_PARAMS_KEY } from "ee/constants/ModuleInstanceConstants";

// Type alias for a function that takes arguments of type T and returns a result of type R
// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FnWithDefaults<T extends any[], TReturn> = (...args: T) => TReturn;

/**
Generic function to apply default values to a given function
Example usage:

const sum = (a: number, b: number): number => a + b;
const sumWithDefaults = applyDefaults(sum, [5, 10]);

console.log(sumWithDefaults()); // 15
console.log(sumWithDefaults(3)); // 13
console.log(sumWithDefaults(3, 4)); // 7

const multiply = (a: number, b: number, c: number): number => a * b * c;
const multiplyWithDefaults = applyDefaults(multiply, [2, 3, 4]);

console.log(multiplyWithDefaults()); // 24
console.log(multiplyWithDefaults(5)); // 60
console.log(multiplyWithDefaults(5, 6)); // 120
console.log(multiplyWithDefaults(5, 6, 7)); // 210

const partialDefaults = applyDefaults(sum, [5]);
console.log(partialDefaults()); // NaN
console.log(partialDefaults(3)); // 3
console.log(partialDefaults(3, 4)); // 7
*/
// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const applyDefaults = <TArgs extends any[], TReturn>(
  fn: FnWithDefaults<TArgs, TReturn>,
  defaults: Partial<TArgs>,
) => {
  // Return a new function that takes arguments of type Partial<T>
  return (...args: Partial<TArgs>) => {
    // Create an array that merges the provided arguments with the default values
    const finalArgs = args.map((arg, index) =>
      arg !== undefined ? arg : defaults[index],
    ) as TArgs;

    // Ensure that any missing arguments at the end are filled in with defaults if available
    for (let i = args.length; i < defaults.length; i++) {
      finalArgs[i] = defaults[i] as TArgs[number];
    }
    // Call the original function with the final arguments
    return fn(...finalArgs);
  };
};

// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getJSActionForEvalContext(entityName: string, entity: any) {
  const jsObjectForEval = ce_getJSActionForEvalContext(
    entityName,
    entity,
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as Record<string, any>;

  // DEFAULT_JS_PARAMS_KEY being present in an JSAction is an indication
  // that this belongs to JSModule instance and the default params needs to be applied
  // otherwise the default flow follows as present in CE.
  if (DEFAULT_JS_PARAMS_KEY in entity) {
    Object.keys(jsObjectForEval).forEach((jsActionKey) => {
      const fn = jsObjectForEval[jsActionKey];
      const defaultParams = entity?.[DEFAULT_JS_PARAMS_KEY]?.[jsActionKey];

      if (typeof fn !== "function" || !defaultParams) return;

      if (Array.isArray(defaultParams)) {
        jsObjectForEval[jsActionKey] = applyDefaults(fn, defaultParams);
        if ("data" in fn) {
          jsObjectForEval[jsActionKey].data = fn.data;
        }
      }
    });

    return jsObjectForEval;
  }

  return jsObjectForEval;
}
